body.gk-user-auth {
  background: url("../images/user_auth_bg.svg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .gk-logo {
    @extend .p-5;

    text-align: center;

    svg {
      height: 3rem;
    }

    a {
      color: $light;
      text-decoration: none;
    }
  }

  .gk-contents {
    @extend .p-0;
    @extend .shadow;
    @include border-radius(0.6rem);

    background-color: $light;
    max-width: 400px;

    .gk-contents-inner {
      @extend .p-4;
    }
  }

  .gk-auth-nav {
    display: flex;

    a {
      @extend .py-4;
      @extend .fw-bold;
      width: 50%;
      text-align: center;
      border-bottom: 0.2rem solid $gray-300;
      text-decoration: none;
      color: $gray-600;

      &:hover {
        background-color: $gray-200;
      }

      &:first-child {
        border-top-left-radius: 0.6rem;
        border-right: 0.05rem solid $gray-300;
      }

      &:last-child {
        border-top-right-radius: 0.6rem;
        border-left: 0.05rem solid $gray-300;
      }

      &.gk-active {
        border-bottom: 0.2rem solid $secondary;
      }
    }
  }

  .gk-steps {
    @extend .px-0;
    @extend .py-4;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    .gk-step {
      @extend .fw-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid rgba($light, 0.25);
      border-radius: 100%;
      color: rgba($light, 0.75);
      text-align: center;
      line-height: 0.75rem;
      font-size: 0.75rem;
      text-decoration: none;

      &.gk-current {
        background-color: rgba($secondary, 0.75);
      }
    }

    a.gk-step {
      background-color: rgba($light, 0.15);
    }

    .gk-divider {
      @extend .mx-1;
      width: 10%;
      height: 1px;
      background-color: rgba($light, 0.25);
    }
  }

  .gk-btn-user-auth {
    @extend .btn;
    @extend .btn-secondary;
    @extend .w-100;
    @extend .btn-lg;
    @extend .text-light;
    @extend .fw-bold;
    @extend .mt-3;
  }
}
