.gk-floating-placeholder {
  $total-padding: $input-btn-padding-y + ($input-btn-padding-y-lg * 2);
  $min-padding: $input-btn-padding-y-sm;

  position: relative;

  .form-control {
    @include border-radius($input-border-radius-lg);

    min-height: $input-height-lg;
    padding-top: $total-padding - $min-padding;
    padding-bottom: $min-padding;

    &:placeholder-shown {
      padding-top: calc(#{$total-padding} / 2);
      padding-bottom: calc(#{$total-padding} / 2);
    }
  }

  .form-select {
    @include border-radius($input-border-radius-lg);

    height: add($input-height-lg, $input-btn-padding-y);
    padding-top: $form-select-padding-y-lg * 2;
    padding-bottom: 0;
  }

  .form-label {
    color: $text-muted;
    font-size: $input-btn-font-size;
    font-weight: $font-weight-normal;
    left: calc(#{$input-btn-padding-x} + #{$border-width});
    margin: 0;
    position: absolute;
    top: $input-btn-padding-y-lg + $min-padding;
    visibility: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .form-select + .form-label {
    visibility: visible;
  }

  .form-control:not(:placeholder-shown),
  .form-select:valid:not(.gk-is-empty) {
    & + .form-label {
      transform-origin: top left;
      transform: translate(0, -$input-btn-padding-y-lg) scale(0.75);
      transition: transform 0.15s ease;
      visibility: visible;
    }
  }

  .invalid-tooltip,
  .info-tooltip {
    visibility: hidden;
    backdrop-filter: blur(10px);
  }

  &.gk-is-invalid {
    @extend .gk-animation-shake;
  }

  &:not(.gk-is-invalid) {
    .form-control,
    .form-select,
    .select2 {
      &:focus,
      &.select2-container--focus,
      &.select2-container--open {
        & ~ {
          .invalid-tooltip,
          .info-tooltip {
            visibility: visible;
            @extend .gk-animation-zoom-in;
          }
        }
      }

      &:hover:not(:focus):not(.select2-container--focus):not(.select2-container--open) {
        & ~ {
          .invalid-tooltip,
          .info-tooltip {
            @extend .gk-animation-zoom-in;
            visibility: visible;
          }

          .invalid-tooltip {
            background-color: rgba($form-feedback-invalid-color, 0.4);
          }
        }
      }
    }
  }

  .gk-form-percent {
    padding-right: add($input-height-inner-quarter, 0.75rem);
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$text-muted}' d='M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 01-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z'/></svg>")
    );
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter bottom
      $input-height-inner-quarter;
    background-size: 0.75rem 0.75rem;

    &:placeholder-shown {
      background-position: right $input-height-inner-quarter center;
    }
  }
}

.form-check {
  .invalid-tooltip {
    visibility: hidden;
    backdrop-filter: blur(10px);
  }

  &:hover {
    .invalid-tooltip {
      @extend .gk-animation-zoom-in;
      visibility: visible;
      background-color: rgba($form-feedback-invalid-color, 0.4);
    }
  }
}
