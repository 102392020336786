@keyframes gk-zoom-in {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: 1;
  }
}

.gk-animation-zoom-in {
  animation: gk-zoom-in 0.3s ease;
}

@keyframes gk-shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  12.5%,
  62.5% {
    transform: translate3d(-2px, 0, 0);
  }

  37.5%,
  87.5% {
    transform: translate3d(2px, 0, 0);
  }
}

.gk-animation-shake {
  animation: gk-shake 0.5s ease both;
}

@keyframes gk-slide-down {
  from {
    transform: translate3d(0, -100%, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
}

.gk-animation-slide-down {
  animation: gk-slide-down 0.3s ease;
}

@keyframes gk-fade-in {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.gk-animation-fade-in {
  animation: gk-fade-in 0.3s ease;
}
