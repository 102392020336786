//
// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.
//
@import "trix/dist/trix";

trix-editor {
  background-color: $input-bg;
  border-color: $input-border-color;
  border-radius: $input-border-radius;
  padding-top: 1.5rem;

  & ~ label {
    position: absolute;
    top: 2.5rem;
    color: $text-muted;
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
    left: $input-btn-padding-x;
  }
}

trix-toolbar .trix-button {
  background: $gray-200;
  border-bottom: 0;

  &.trix-active {
    background: $gray-400;
  }
}

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}
