@import "custom";
@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

.turbo-progress-bar {
  background-color: $primary;
}

.btn-secondary {
  @include button-variant(
    $secondary,
    $secondary,
    $color: $light,
    $hover-color: $light
  );
}

.btn-outline-secondary {
  @include button-outline-variant($secondary, $color-hover: $light);
}

.pagy-bootstrap-nav .pagination {
  justify-content: center;
}

@import "utils/animation";

@import "components/flatpickr";
@import "components/floating-placeholder";
@import "components/tables";
@import "components/toasts";
@import "components/expand";

@import "layouts/app";
@import "layouts/admin";
@import "layouts/admin_auth";
@import "layouts/user_auth";

@import "~select2/src/scss/core";
@import "components/select2";

$flag-icon-css-path: "~flag-icon-css/flags";
@import "~flag-icon-css/sass/flag-icon";

@import "components/actiontext";
