body.gk-admin-auth {
  background: $gray-900;

  .gk-logo {
    @extend .p-3;

    svg {
      height: 2.5rem;
    }

    a {
      color: $light;
      text-decoration: none;
    }
  }

  .gk-contents {
    @extend .p-4;
    @extend .shadow;
    @include border-radius(0.6rem);

    margin-top: 1.75rem;
    position: relative;

    background-color: $gray-200;
    max-width: 400px;
  }

  .gk-btn-admin-auth {
    @extend .btn;
    @extend .btn-secondary;
    @extend .w-100;
    @extend .btn-lg;
    @extend .text-light;
    @extend .fw-bold;
    @extend .mt-3;
  }
}
