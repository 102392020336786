.gk-toasts {
  @extend .p-3;
  @extend .toast-container;

  z-index: 1100;
  position: fixed;
  bottom: 0;
  right: 1rem;

  .toast {
    cursor: pointer;
    border: 1px solid $secondary;

    &.toast-error {
      border: 1px solid $danger;
      background-color: rgba(lighten($danger, 45%), 0.85);
      color: mix($body-color, $danger, 60%);
    }
  }
}
