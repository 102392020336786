@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$primary: #1c39bb;
$secondary: #d58c2c;

$font-family-sans-serif: "Open Sans", system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-weight: 700;

$btn-font-weight: 700;

// Create your own map
$custom-colors: (
  "quiet": $gray-600
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$enable-shadows: true;
$enable-important-utilities: false;
