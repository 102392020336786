.select2-container--bootstrap5 {
  display: block;

  .selection {
    display: block;
  }

  .select2-selection {
    display: block;
    width: 100%;
    padding-right: ($form-select-padding-x + $form-select-indicator-padding) +
      2rem;
    padding-left: $form-select-padding-x;
    font-family: $form-select-font-family;
    @include font-size($form-select-font-size);
    font-weight: $form-select-font-weight;
    line-height: $form-select-line-height;
    color: $form-select-color;
    background-color: $form-select-bg;
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-position: $form-select-bg-position;
    background-size: $form-select-bg-size;
    border: $form-select-border-width solid $form-select-border-color;
    @include box-shadow($form-select-box-shadow);

    @include border-radius($input-border-radius-lg);
    height: add($input-height-lg, $input-btn-padding-y);
    padding-top: $form-select-padding-y-lg * 2;
    padding-bottom: 0;

    .select2-selection__rendered {
      padding: 0;
      height: 1.875rem;
      line-height: 1.875rem;
    }

    .select2-selection__arrow {
      display: none;
    }
  }

  &.select2-container--focus,
  &.select2-container--open {
    .select2-selection {
      border-color: $form-select-focus-border-color;
      outline: 0;
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  & + .form-label {
    visibility: visible;
  }

  &:not(.select2-empty) + .form-label {
    transform-origin: top left;
    transform: translate(0, -$input-btn-padding-y-lg) scale(0.75);
    transition: transform 0.15s ease;
  }

  @each $state, $data in $form-validation-states {
    @include form-validation-state-selector($state) {
      .select2-selection {
        border-color: map-get($data, color);

        @if $enable-validation-icons {
          padding-right: $form-select-feedback-icon-padding-end;
          background-image: escape-svg($form-select-indicator),
            escape-svg(map-get($data, icon));
          background-position: $form-select-bg-position,
            $form-select-feedback-icon-position;
          background-size: $form-select-bg-size, $form-select-feedback-icon-size;
        }

        &:focus {
          border-color: map-get($data, color);
          box-shadow: 0 0 0 $input-focus-width rgba(map-get($data, color), 0.25);
        }
      }
    }
  }

  .select2-dropdown {
    @include reset-text();
    @include font-size($popover-font-size);
    line-height: $line-height-sm;
    background-color: $popover-bg;
    border: $popover-border-width solid $popover-border-color;
    @include border-radius($popover-border-radius);
    @include box-shadow($popover-box-shadow);

    .select2-search {
      @extend .p-2;

      .select2-search__field {
        @extend .form-control;
        @extend .form-control-sm;
      }
    }

    .select2-results {
      overflow-y: auto;
      max-height: 40vh;
    }

    .select2-results__option {
      @extend .p-2;

      .breadcrumb-item {
        color: $breadcrumb-active-color;

        + .breadcrumb-item {
          &::before {
            color: $breadcrumb-divider-color;
          }
        }

        &.active {
          color: $body-color;
        }
      }

      &--highlighted[aria-selected] {
        background-color: $component-active-bg;
        color: $component-active-color;

        .breadcrumb-item {
          color: $gray-400;

          + .breadcrumb-item {
            &::before {
              color: $gray-400;
            }
          }

          &.active {
            color: $component-active-color;
          }
        }
      }
    }
  }

  .select2-selection__clear {
    @extend .btn-close;
    position: absolute;
    color: transparent;
    right: 2rem;
    top: 0.75rem;
    transform: scale(0.75);

    &:hover {
      color: transparent;
    }
  }
}
