body.consent-scroll-lock {
  touch-action: none;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  overflow: hidden;
}

body.gk-app {
  padding: 0;
  margin: 0 auto;
  color: #333333;
  background: #ffffff;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  .pl-0 {
    padding-left: 0 !important;
  }
  .pr-0 {
    padding-right: 0 !important;
  }
  .ml-0 {
    margin-left: 0 !important;
  }
  .mr-0 {
    margin-right: 0 !important;
  }

  /* ************** Header style  ****************** */

  .header_top {
    padding: 6px 0 10px 0;
    background: #333333;
  }
  .header_top span {
    margin-right: 15px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #ffffff;
  }
  .header_top span img {
    margin-right: 5px;
  }
  .header_top span a {
    color: #ffffff;
  }
  .header_social_media {
    float: right;
  }
  .header_social_media ul {
    list-style-type: none;
  }
  .header_social_media ul li {
    margin: 0 15px;
    display: inline;
  }
  .custom-navbar {
    width: 100%;
    padding: 0 0 0.5rem 0;
  }
  .custom-navbar-nav li a {
    margin: 0 30px 0 0;
    color: #333333 !important;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.015em;
  }
  .custom-navbar-nav li a.active {
    color: #1c39bb !important;
  }
  .custom-navbar-nav li a.trade-btn {
    margin: 0 !important;
    padding: 9px 44px !important;
    color: #ffffff !important;
    background-color: #1c39bb;
    border-radius: 20px;

    &:hover {
      background-color: lighten(#1c39bb, 10%);
    }
  }
  .hdr_second {
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.07);
  }
  .navbar-light .navbar-brand {
    padding: 0;
  }

  /* ************* Slide  ****************** */

  .slide {
    background-image: url("../images/frontend/slide.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .slide h1 {
    font-family: "Lora", serif;
    font-size: 76px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .slide a {
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    background: #1c39bb;
    border-radius: 26px;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #ffffff;
    padding: 10px 30px;
  }

  /* ****************** Market trends ************** */

  .market_trends {
    padding: 25px 0 50px;
  }
  .market_trends_title {
    margin-bottom: 55px;
    text-align: center;
    font-family: "Lora", serif;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
  }
  .market_box {
    background-clip: padding-box;
    border-radius: 6px;
    padding: 25px;
    background: #ffffff;
    box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.22);
    margin-bottom: 20px;
    position: relative;
    border: 1px solid transparent;
    background-clip: padding-box;
  }
  .market_box::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(90deg, #2d49c7, rgba(0, 0, 0, 0));
    content: "";
    z-index: -1;
    border-radius: 6px;
  }
  .mobile_market {
    display: none;
  }
  .market_box h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #333333;
  }
  .market_box p {
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .green {
    color: #34b36a;
  }
  .chart_red {
    color: #eb5757;
  }
  .chart_green {
    color: #27ae60;
  }
  .portfolio_green {
    color: #27ae60;
  }

  /* ************** Investment wines ******************* */

  .investment_wines {
    padding: 60px 0 40px 0;
    background: #f5f5f5;
  }
  .investment_wines_title {
    font-family: "Lora", serif;
    font-weight: 500;
    font-size: 48px;
    letter-spacing: -0.015em;
    line-height: 72px;
    margin-bottom: 0px;
  }
  .investment_wines_description {
    letter-spacing: -0.015em;
    color: #4f4f4f;
    font-size: 18px;
    line-height: 50px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .investment_wines_main {
    padding: 20px 20px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .investment_wines_main input {
    //   border: 1px solid #e0e0e0;
    //   box-sizing: border-box;
    //   border-radius: 6px;
    margin-bottom: 15px;
    height: 55px;
    //   outline: none;
    //   text-decoration: none;
    //   box-shadow: none;
    //   font-family: "Open Sans", sans-serif;
    font-size: 16px;
    background-image: url("../images/frontend/search.svg");
    background-position: right 15px center;
    background-repeat: no-repeat;
    padding: 0.375rem 2.3rem 0.375rem 0.75rem;
  }
  .investment_wines_main input.explore_all {
    height: 48px !important;
    color: #ffffff;
    background: #1c39bb;
    border-radius: 4px;
  }
  .investment_wines_main input.explore_all::placeholder {
    color: #ffffff;
  }
  .investment_wines_main input:hover,
  .investment_wines_main input:focus {
    box-shadow: none;
  }
  .investment_wines_main select {
    position: relative;
    height: 48px;
    background: #fefefe;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 15px;
    outline: none;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #828282;
    background-image: url("../images/frontend/arrow_down.svg");
    background-position: right 15px center;
    background-repeat: no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &.select-display-order {
      height: 36px;
      margin: 0;
    }
  }
  .investment_wines_main select::placeholder {
    color: #828282;
  }
  .investment_wines_main select:hover,
  .investment_wines_main select:focus {
    box-shadow: none;
  }

  .custom-nav-tabs {
    margin-top: 15px;
    margin-bottom: 20px;
    border: none;
    justify-content: end;
  }
  .custom-nav-tabs li a {
    padding: 8px 10px;
    margin: 0 0 0 10px;
    border: 1px solid #bdbdbd !important;
    box-sizing: border-box;
    border-radius: 4px;
    color: #bdbdbd;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    white-space: nowrap;
  }
  .custom-nav-tabs li form {
    padding-left: 10px;
  }
  .custom-nav-tabs li a.active {
    border: 1px solid #1c39bb !important;
    color: #1c39bb !important;
  }
  table {
    font-family: "Open Sans", sans-serif;
  }
  .product_table,
  .chart_table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border: 0.5px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 4px;
    table-layout: fixed;
  }

  /* top-left border-radius */
  table tr:first-child th:first-child,
  table.Info tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }
  /* top-right border-radius */
  table tr:first-child th:last-child,
  table.Info tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }
  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }
  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  .product_table tr th,
  .chart_table tr th {
    color: #4f4f4f;
    letter-spacing: -0.015em;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    border: none;
    border-bottom: 0.5px solid #bdbdbd;
    background: #fafbff;

    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }

    a.gk-sortable-header {
      color: #4f4f4f;
      text-decoration: none;
    }
  }
  .product_table tr td,
  .chart_table tr td {
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    white-space: nowrap;
    vertical-align: middle;

    &:not(.link-row) {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }

    &.link-row {
      padding: 0;

      & > a {
        display: block;
        width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:first-of-type > a {
        padding-left: 1rem;
      }
      &:last-of-type > a {
        padding-right: 1rem;
      }
    }
  }
  .table_outer {
    position: relative;

    & > .table_inner {
      overflow-x: scroll;
      overflow-y: visible;
      margin-left: calc(max(30vw, 400px));
      margin-right: calc(max(5vw, 100px));

      & > .product_table {
        border-collapse: collapse;
        border: 0;

        thead tr:first-of-type {
          border-top: 0.5px solid #bdbdbd;
          border-bottom: 0.5px solid #bdbdbd;
          background: #fafbff;

          td,
          th {
            border-top: 0.5px solid #bdbdbd;
            border-bottom: 0.5px solid #bdbdbd;

            &:first-of-type,
            &:last-of-type {
              margin-top: -0.5px;
            }
          }
        }

        tbody tr:last-of-type {
          border-bottom: 0.5px solid #bdbdbd;

          td,
          th {
            border-bottom: 0.5px solid #bdbdbd;

            &:first-of-type,
            &:last-of-type {
              height: 56.5px;
            }
          }
        }

        tr td {
          height: 56px;

          &:last-of-type {
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 1rem;
          }
        }

        tr td,
        tr th {
          &:first-of-type {
            position: absolute;
            left: 0;
            border-left: 0.5px solid #bdbdbd;
            border-right: 0.5px solid rgba(#bdbdbd, 0.5);
          }
          &:last-of-type {
            position: absolute;
            right: 0;
            border-right: 0.5px solid #bdbdbd;
            border-left: 0.5px solid rgba(#bdbdbd, 0.5);
          }
        }
      }
    }
  }
  .chart_table_outer {
    position: relative;

    & > .chart_table_inner {
      overflow-x: scroll;
      overflow-y: visible;
      margin-left: calc(max(15vw, 200px));
      margin-right: 10px;

      & > .chart_table {
        border-collapse: collapse;
        border: 0;

        thead tr:first-of-type {
          border-top: 0.5px solid #bdbdbd;
          border-bottom: 0.5px solid #bdbdbd;
          background: #fafbff;

          td,
          th {
            border-top: 0.5px solid #bdbdbd;
            border-bottom: 0.5px solid #bdbdbd;

            &:first-of-type,
            &:last-of-type {
              margin-top: -0.5px;
            }
          }
        }

        tbody tr:last-of-type {
          border-bottom: 0.5px solid #bdbdbd;

          td,
          th {
            border-bottom: 0.5px solid #bdbdbd;

            &:first-of-type,
            &:last-of-type {
              height: 56.5px;
            }
          }
        }

        tr td {
          height: 56px;

          &:last-of-type {
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 1rem;
          }
        }

        tr td,
        tr th {
          &:first-of-type {
            position: absolute;
            left: 0;
            border-left: 0.5px solid #bdbdbd;
            border-right: 0.5px solid rgba(#bdbdbd, 0.5);
          }
          &:last-of-type {
            position: absolute;
            right: 0;
            border-right: 0.5px solid #bdbdbd;
          }
        }
      }
    }
  }
  .product_table tr td:last-of-type,
  .chart_table tr td:last-of-type {
    padding-top: 0;
    padding-bottom: 0;

    a {
      display: block;
      text-align: center;
      color: #ffffff;
      background: #d58c2c;
      border-radius: 4px;
      padding: 7px 20px;
      outline: none;
      text-decoration: none;
    }
  }
  .product_table tbody tr:nth-of-type(odd),
  .chart_table tbody tr:nth-of-type(odd) {
    &,
    td {
      background: #fafbff;
    }
  }
  .product_table tbody tr:nth-of-type(even),
  .chart_table tbody tr:nth-of-type(even) {
    &,
    td {
      background: #fcfcfc;
    }
  }
  .transactions_table tr td span.transactions_buy {
    padding: 5px 20px;
    color: #d58c2c;
    /* background: #D58C2C; */
    border-radius: 4px;
    outline: auto;
    text-decoration: none;
  }
  .chart_table_outer {
    margin-bottom: 44px;
  }
  .products {
    display: block;
    background: #ffffff;
    border: 0.5px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 4px;

    a {
      text-decoration: none;
      color: #333;
    }

    .row > div:last-of-type > a {
      display: inline;
      text-align: center;
      color: #ffffff;
      background: #d58c2c;
      border-radius: 4px;
      padding: 7px 20px;
      outline: none;
      text-decoration: none;
    }
  }
  .products-mobile {
    display: none;
  }
  .products h3 {
    color: #333333;
    font-size: 14px;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  .products span {
    display: block;
    font-size: 11px;
  }
  .products .col-md-12 {
    padding: 8px;
    text-decoration: none;
    color: #333;
    display: block;

    &:not(:last-of-type) {
      border-bottom: 0.5px solid #bdbdbd;
    }

    &:hover {
      background-color: #f0f0f0 !important;
    }
  }
  .products .col-md-12:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .products .col-md-12:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .products .col-md-12:nth-of-type(odd) {
    background: #fff;
  }
  .products .col-md-12:nth-of-type(even) {
    background: #fcfcfc;
  }

  /* **************** Today ************* */

  .today {
    position: relative;
  }
  .today img {
    width: 100%;
  }
  .today .today_content {
    text-align: center;
    width: 45%;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0px auto;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .today_content p {
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .today_btn {
    margin-top: 25px;
  }
  .btn_join_today {
    color: #1c39bb;
    background: #ffffff;
    border: 1.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 32px;
  }
  .btn_join_today:hover {
    color: #ffffff;
    background: transparent;
  }
  .btn_contact_us {
    color: #ffffff;
    border: 1.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 30px;
  }
  .btn_contact_us:hover {
    color: #1c39bb;
    background: #ffffff;
  }

  /* ************* Footer **************** */

  #footer {
    padding-top: 20px;
    padding-bottom: 70px;
  }
  .footer_nav {
    padding: 0;
    list-style: none;
  }
  .footer_nav li {
    display: inline;
  }
  .social_media {
    text-align: center;
  }
  .social_media ul {
    padding: 0;
    list-style: none;
  }
  .social_media ul li {
    margin: 0 20px;
    display: inline;
  }
  .copyright {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #4f4f4f;
    display: block;
  }
  .footer_logo img {
    display: block;
    margin: 0px auto;
  }
  .footer_navbar a {
    color: #333333;
    letter-spacing: -0.015em;
    font-size: 15px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    outline: none;
    display: block;
    margin-top: 30px;
    text-decoration: none;
  }
  .footer_navbar a.footer_logo {
    margin-top: 0px !important;
  }
  .footer_navbar a.end {
    text-align: end;
  }
  .footer_navbar a:hover {
    text-decoration: none;
  }
  #footer hr {
    background: #bdbdbd;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .footer_logo_mobile {
    display: none !important;
  }

  .trade-btn-mobile {
    display: none;
  }

  /* **************** Second page **************** */

  .frequently_question {
    background: #f5f5f5;
    padding: 40px 0 50px 0;
  }
  .frequently_question_title {
    font-family: "Lora", serif;
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .ask_questions .card {
    border: none;
    border-radius: 0;
    margin-bottom: 15px;
  }
  .ask_questions .card-header {
    background: rgba(255, 255, 255, 0.9);
    border: none;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  }
  .card-block {
    padding: 0.15rem 2.25rem 0.15rem 2.5rem;
  }
  .ask_questions .card-header a {
    display: block;
    color: #333333;
    letter-spacing: -0.015em;
    font-size: 17px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    text-decoration: none;

    i {
      margin-top: 4px;
    }
  }
  .about_first {
    position: relative;
    background: #f5f5f5;
    padding: 45px 0;
  }
  .about_first_back {
    float: right;
    width: 85%;
    z-index: 1;
  }
  .about_first_front {
    z-index: 2;
    width: 85%;
    margin-top: -75%;
    margin-left: 12%;
  }
  .about_second {
    padding: 80px 0;
  }
  .about_second_back {
    float: left;
    width: 85%;
    z-index: 1;
  }
  .about_second_front {
    z-index: 2;
    width: 85%;
    margin-top: -85%;
    margin-left: 3%;
  }
  .about_first_content label {
    margin-top: 50px;
    letter-spacing: -0.015em;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .about_first_content label.label_first {
    color: #d58c2c;
  }
  .about_first_content label.label_second {
    color: #d58c2c;
  }
  .about_first_content h1 {
    color: #333333;
    letter-spacing: -0.015em;
    font-size: 42px;
    font-family: "Lora", serif;
    font-style: normal;
    font-weight: 500;
  }
  .about_first_content p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #4f4f4f;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  .btn_learn_more {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    background: #1c39bb;
    border-radius: 25.5px;
    padding: 12px 40px;
  }
  .btn_learn_more:hover {
    color: #ffffff;
    outline: none;
    text-decoration: none;
  }
  .about_second_content_mobile {
    display: none;
  }
  .jero_chart_top {
    padding: 35px 0 35px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .jero_chart_top_text {
    flex-grow: 1;
  }
  .jero_chart_top_right {
    flex-grow: 0;
    padding-left: 30px;
    text-align: right;
  }
  .jero_chart_top h1 {
    display: inline-block;
    font-family: "Lora", serif;
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    line-height: 36px;
    letter-spacing: -0.015em;
  }
  .jero_chart_top span {
    color: #9d9d9d;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
  }
  .jero_chart_top_btn a.btn_sell {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #f5f5f5;
    background: #1c39bb;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 6px 24px;
    text-decoration: none;
  }
  .jero_chart_top_btn a.btn_sell:hover {
    text-decoration: none;
    background-color: lighten(#1c39bb, 10%);
  }
  .jero_chart_top_btn a.btn_buy {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #f5f5f5;
    background: #d58c2c;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 6px 24px;
    text-decoration: none;
    margin-bottom: 4px;
  }
  .jero_chart_top_btn a.btn_buy:hover {
    text-decoration: none;
    background-color: lighten(#d58c2c, 10%);
  }
  .your_portfolio {
    padding: 50px 0 40px 0;
    background: #f5f5f5;
  }
  .your_portfolio_table tr th {
    border-bottom: none;
  }
  .your_portfolio_table tr td {
  }
  .your_portfolio_table_mobile {
    display: none;
  }
  .your_portfolio_title {
    font-family: "Lora", serif;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    color: #333333;
    letter-spacing: -0.015em;
    margin-bottom: 20px;
  }
  .portfolio-custom-nav-tabs {
    display: inline-flex;
    float: right;
    margin-top: 12px;
    margin-bottom: 0.5rem;
  }
  .portfolio-custom-nav-tabs li a {
    background-color: #ffffff;
  }
  .your_portfolio_table tbody tr:nth-of-type(odd) {
    background: #ffffff;
  }
  .your_portfolio_table tbody tr:nth-of-type(even) {
    background: #fcfcfc;
  }
  .jero_chart_main {
    padding: 20px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    margin-bottom: 40px;
  }
  .jero_chart_main_top {
    padding: 0 30px 0 20px;
  }
  .jero_chart_main h1 {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #333333;
    font-size: 32px;
    line-height: 46px;
  }
  .jero_chart_main h1 span.subtitle {
    font-size: 22px;
  }
  .jero_chart_tabs {
    float: right;
    margin-top: 15px;
    margin-bottom: 20px;
    border: none;
    justify-content: end;
  }
  .jero_chart_tabs li a {
    padding: 7px 7px;
    margin: 0 0 0 10px;
    border: 1px solid #bdbdbd !important;
    box-sizing: border-box;
    border-radius: 4px;
    color: #bdbdbd;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
  }
  .jero_chart_tabs li a.active {
    border: 1px solid #1c39bb !important;
    color: #1c39bb !important;
  }
  .jero_chart_tabs_mobile {
    display: none;
  }
  .jero_prices {
    width: 100%;
    margin-bottom: 15px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 12px;
    overflow: auto;
    cursor: pointer;
  }
  .jero_prices span {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    color: #333333;
    float: left;
    cursor: pointer;
  }
  .jero_chart_form_control {
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    padding: 0 30px;
    margin-bottom: 0;
    display: inline-block;
    background-color: transparent;
    background-image: url(../images/frontend/arrow_down.svg);
    background-position: right 5px center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .jero_prices_dropdown {
    width: 100%;
    background: #dddddd;
    box-sizing: border-box;
    overflow: auto;
    position: absolute;
    top: 45px;
    z-index: 100;
    display: block;
  }
  .jero_prices_dropdown.is-hidden {
    display: none;
  }
  .jero_prices_dropdown a {
    color: #333333;
    text-decoration: underline;
    padding: 5px 12px;
    display: block;
  }
  .jero_prices_dropdown a:hover {
    background-color: #ccc;
  }
  .jero_prices_dropdown span.jero_dropdown_label {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    color: #333333;
    float: left;
  }
  .jero_prices_dropdown label {
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    padding: 0 10px;
    margin-bottom: 0;
    display: inline-block;
    background-color: transparent;
  }

  .jero_chart_form_control:focus {
    box-shadow: none;
  }
  .next_investment {
    padding: 34px 0 14px 0;
  }

  /* ****************** Profile page **************** */

  .profile_details {
    padding: 40px 0 80px 0;
  }
  .profile_details_top {
    margin-bottom: 30px;
  }
  .profile_details_top h1 {
    font-family: "Lora", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    letter-spacing: -0.015em;
    color: #333333;
  }
  .profile_details_top p {
    color: #4f4f4f;
    letter-spacing: -0.015em;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .personal_info h3 {
    display: inline-block;
    color: #000000;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .personal_info a.edit_profile {
    float: right;
    border: 1px solid #1c39bb;
    box-sizing: border-box;
    border-radius: 20px;
    text-decoration: none;
    color: #1c39bb;
    letter-spacing: -0.015em;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    padding: 2px 13px;
  }

  .personal_info_box {
    padding: 25px 22px 0 22px;
    border: 0.5px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .personal_info_box_content {
    margin-bottom: 25px;
  }
  .personal_info_box_content label {
    width: 40%;
    color: #333333;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 0;
  }
  .personal_info_box_content span {
    color: #828282;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
  }
  .personal_info_box_content span.verfication {
    background: #eb5757;
    border-radius: 25px;
    color: #ffffff;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    padding: 5px 12px;
  }
  .change_password {
    text-align: end;
    display: block;
    margin: 5px 0 15px 0;
    color: #d58c2c;
    letter-spacing: -0.015em;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .change_password:hover {
    color: #d58c2c;
    text-decoration: none;
  }
  .see_more {
    text-align: center;
    display: block;
    margin: 5px 0 15px 0;
    color: #d58c2c;
    letter-spacing: -0.015em;
    font-size: 17px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .see_more:hover {
    color: #d58c2c;
    text-decoration: none;
  }

  /* *************** Popup ********************** */

  .trading_title {
    text-align: center;
    color: #333333;
    letter-spacing: -0.015em;
    font-family: "Lora", serif;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .trading_window {
    padding: 25px 45px 25px 45px;
  }
  .trading_window .btn-primary {
    background-color: #1c39bb !important;

    &:hover {
      background-color: lighten(#1c39bb, 10%) !important;
    }
  }
  .trading_window .modal-header,
  .trading_window .modal-footer {
    border: 0;
  }
  .close_popup {
    right: 25px;
    position: absolute;
    top: 25px;
    cursor: pointer;
  }
  .trading_popup label {
    color: #333333;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .trading_popup h2 {
    color: #333333;
    letter-spacing: -0.015em;
    line-height: 40px;
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  .trading_popup p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #828282;
    letter-spacing: -0.015em;
    font-size: 18px;
    line-height: 20px;
  }
  .trading-form-control {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 15px;
    color: #333333;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    height: 50px;
  }
  .trading-form-control::placeholder {
    color: #333333;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
  }
  .trading-form-control:hover {
    box-shadow: none;
  }
  .custom-input-group {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 30px;
    margin-bottom: 15px;
  }
  .custom-input-group button {
    height: 50px;
    background-color: transparent;
  }
  .custom-input-group button:hover,
  .custom-input-group button:focus {
    box-shadow: none;
  }
  .custom-input-group input {
    text-align: center;
    border: none;
    color: #333333;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    height: 50px;
  }
  .custom-input-group input:hover,
  .custom-input-group input:focus {
    box-shadow: none;
  }
  .expiratrion-form-control {
    padding: 0 30px;
    position: relative;
    height: 50px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 40px;
    outline: none;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #333333;
  }
  .expiratrion-form-control:hover {
    box-shadow: none;
  }
  .btn-order {
    width: 100%;
    background: #1c39bb;
    border-radius: 4px;
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: #ffffff;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 15px;
  }
  .btn-order:hover {
    box-shadow: none;
  }
  .modal-content {
    background: #ffffff;
    border-radius: 20px;
  }

  .wine-accordion {
    padding: 45px 0;
    padding-top: 20px;

    .wine-accordion-item {
      background: #f5f5f5;
      padding: 15px 30px;

      &:not(:first-child) {
        margin-top: 30px;
      }

      .wine-accordion-item-header {
        a {
          color: #333333;
          text-decoration: none;

          h3 {
            font-weight: 500;
            font-size: 20px;
            margin: 0;
          }
        }
      }
      .wine-accordion-item-content {
        border-top: 1px solid #ccc;
        margin-top: 10px;
        padding-top: 20px;

        table td {
          padding: 10px 0;
        }

        table tr:first-child td {
          padding-top: 0;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .modal-lg {
      max-width: 750px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .slide {
      height: 80vh;
    }
  }
  @media only screen and (max-width: 1199px) {
    .today img {
      min-height: 240px;
    }
  }
  @media only screen and (max-width: 991px) {
    .header_social_media ul {
      padding: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .today img {
      min-height: 150px;
    }
    .today_btn {
      margin-top: 10px;
    }
    .btn_join_today {
      padding: 10px 30px;
    }
    .btn_contact_us {
      padding: 10px 30px;
    }
    .product_table {
      display: none;
    }
    .chart_table {
      display: block;
    }
    .chart_table {
      display: table;
    }
    .your_portfolio_table_mobile {
      display: table;
    }
    .your_portfolio_table tr th {
      border-bottom: 0.5px solid #bdbdbd;
    }
    .today_content p {
      padding: 0 15px;
      font-size: 16px;
    }
    .today .today_content {
      width: 80%;
    }
    .footer_logo_desktop {
      display: none !important;
    }
    .footer_logo_mobile {
      display: block !important;
    }
    .footer_navbar a {
      text-align: center;
    }
    .footer_navbar a.end {
      text-align: center;
    }
    #footer hr {
      display: none;
    }
    .social_media {
      margin-top: 20px;
    }
    .investment_wines_main {
      padding: 20px 0px;
      background-color: transparent;
    }
    .investment_wines_main input {
      border: 1px solid transparent;
      background-image: none;
    }
    .investment_wines_main select {
      border: 1px solid transparent;
    }
    .custom-nav-tabs li a {
      background-color: #ffffff;
    }
    .investment_wines {
      padding: 60px 0 15px 0;
      margin-bottom: 25px;
    }
    .header_top {
      display: none;
    }
    .products-mobile {
      display: block;
    }
    .products-desktop {
      display: none;
    }
    .frequently_question_title {
      letter-spacing: -0.015em;
      font-weight: 500;
      font-size: 24px;
    }
    .ask_questions a:after {
      top: 25px;
      right: 15px;
    }
    .frequently_question_title {
      margin-bottom: 25px;
    }
    #footer {
      padding-top: 40px;
    }
    .about_first_content {
      text-align: center;
    }
    .about_img_mobile {
      margin-top: 60px;
    }
    .about_first_back {
      float: right;
      width: 95%;
      z-index: 1;
    }
    .about_first_front {
      z-index: 2;
      width: 95%;
      margin-top: -83%;
      margin-left: 1%;
    }
    .about_first_content label {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: -0.015em;
      margin-top: 0;
    }
    .about_first_content label.label_first {
      color: #1c39bb;
    }
    .about_first_content h1 {
      color: #333333;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }
    .about_first_content p {
      font-size: 14px;
    }
    .btn_learn_more {
      padding: 15px 40px;
    }
    .about_second_back {
      float: left;
      width: 95%;
      z-index: 1;
    }
    .about_second_front {
      z-index: 2;
      width: 95%;
      margin-top: -100%;
      margin-left: 4%;
    }
    .about_second_content_desktop {
      display: none;
    }
    .about_second_content_mobile {
      display: block;
      margin-bottom: 60px;
    }
    .about_first {
      padding: 43px 0 53px 0;
    }
    .about_second {
      padding: 43px 0;
    }
    .jero_chart_top {
      display: block;
      text-align: center;
    }
    .jero_chart_top_text {
      padding-bottom: 35px;
    }
    .jero_chart_top_right {
      text-align: center;
      padding: 0;
    }
    .jero_chart_top h1 {
      font-size: 28px;
      margin-bottom: 0;
    }
    .jero_chart_top span {
      display: block;
      font-size: 12px;
    }
    .jero_chart_top {
      padding: 25px 0;
    }
    .jero_chart_tabs li:first-of-type a {
      margin: 0;
    }
    .jero_chart_tabs_desktop {
      display: none;
    }
    .jero_chart_tabs_mobile {
      float: unset;
      display: flex;
      justify-content: center;
    }
    .row_mobile {
      display: block;
    }
    .jero_chart_tabs_tang {
      float: unset;
      display: flex;
      justify-content: center;
      margin: 0;
    }
    .portfolio-custom-nav-tabs {
      display: none;
    }
    .your_portfolio_title {
      display: block;
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .your_portfolio {
      padding: 25px 0 15px 0;
    }
    .product_table tr td span,
    .chart_table tr td span {
      color: #4f4f4f;
      font-size: 11px;
    }
    .profile_details_top {
      text-align: center;
      margin-bottom: 40px;
    }
    .profile_details_top h1 {
      font-size: 28px;
    }
    .profile_details_top p {
      color: #828282;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .trading_window {
      padding: 40px 15px 68px 15px;
    }
    .bd-example-modal-lg {
      padding-right: 0px !important;
    }
    .trading_title {
      font-size: 26px;
    }
    .close_popup {
      right: 20px;
      position: absolute;
      top: 20px;
      width: 16px;
    }
    .trading_popup h2 {
      font-size: 20px;
    }
    .btn-order {
      line-height: 50px;
    }
    #myChart {
      height: 250px !important;
    }
    .chart_table {
      display: none;
    }
    .next_investment input {
      border: 1px solid #f2f2f2;
    }
    .next_investment select {
      border: 1px solid #f2f2f2;
    }
    .profile_details {
      padding: 40px 0 50px 0;
    }
    .custom-nav-tabs .nav-item {
      flex: 1 1 0px;
    }
    .custom-nav-tabs li a {
      text-align: center;
      padding: 8px 5px;
    }
    .custom-nav-tabs .nav-item:last-child {
      flex: none;
      width: 100%;
    }
    .custom-nav-tabs .nav-item:last-child form {
      padding: 0;
      padding-top: 10px;
    }
    .custom-nav-tabs .nav-item:last-child a {
      padding: 8px 10px;
    }
    .custom-nav-tabs .nav-item:first-child a {
      margin: 0 0px 0 0px;
    }
    .jero_chart_main {
      margin-bottom: 30px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .mobile_market {
      display: block;
    }
    .desktop_market {
      display: none;
    }
    .market_box {
      padding: 15px 20px 15px 20px;
    }
  }
  @media only screen and (max-width: 480px) {
    .investment_wines {
      padding: 30px 0 15px 0;
      margin-bottom: 25px;
    }
    .today_content p {
      padding: 0 15px;
      font-size: 12px;
      line-height: 25px;
    }
    .today .today_content {
      width: 100%;
    }
    .btn_join_today {
      padding: 6px 25px;
      font-size: 10px;
      margin-right: 5px;
    }
    .btn_contact_us {
      padding: 6px 25px;
      font-size: 10px;
    }
    .investment_wines_title {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 10px;
    }
    .investment_wines_description {
      line-height: 25px;
      font-size: 13px;
      margin-bottom: 10px;
    }
    .market_trends {
      padding: 30px 0 35px;
    }
    .market_trends_title {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 20px;
    }
    .market_box_mobile {
      width: 70%;
      display: inline-block;
    }
    .market_box_mobile p {
      display: inline-block;
    }
    .market_box_mobile_right {
      float: right;
      margin-top: 45px;
    }
    .slide {
      height: 45vh;
    }
    .slide h1 {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.015em;
    }
    .slide a {
      font-size: 14px;
    }
    .custom-navbar {
      padding: 0;
    }
    .navbar-light .navbar-toggler {
      border: none;
      outline: none;
    }
    .navbar-brand img {
      width: 70%;
    }
    .trade-btn-mobile {
      display: block;
      margin: 5px 0 0 0 !important;
      padding: 6px 35px !important;
      color: #ffffff !important;
      background-color: #1c39bb;
      border-radius: 20px;
      font-size: 12px;
      text-decoration: none;

      &:hover {
        background-color: lighten(#1c39bb, 10%);
      }
    }
    .trade-btn {
      display: none;
    }
  }
  @media only screen and (max-width: 414px) {
  }
  @media only screen and (max-width: 370px) {
    .custom-nav-tabs li a {
      padding: 8px 0px;
    }
  }
  @media only screen and (max-width: 320px) {
  }

  .gk-chart-parent {
    position: relative;
    width: 100%;
    padding-top: 25%;

    .gk-chart {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .gk-chart-mobile {
    display: none !important;
  }
  @media only screen and (max-width: 767px) {
    .gk-chart-parent {
      padding-top: 75%;
    }
    .gk-chart:not(.gk-chart-mobile) {
      display: none !important;
    }
    .gk-chart-mobile {
      display: block !important;
    }
  }

  .cost-table tr {
    th,
    td {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 300;
      color: #828282;
      letter-spacing: -0.015em;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .cost-table tr:last-of-type {
    th,
    td {
      color: #333333;
      letter-spacing: -0.015em;
      line-height: 40px;
      font-size: 24px;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
    }
  }

  input,
  select {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }

  .order-btn {
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    background: #143232;
    border-radius: 26px;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #ffffff;
    padding: 10px 30px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 10px;
      height: 15px;
      width: 15px;
    }
  }
}
