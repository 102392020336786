body.gk-admin {
  background: $light;
  min-height: 100%;

  .gk-admin-container {
    display: flex;
    min-height: 100%;
  }

  .gk-admin-nav {
    @extend .shadow-lg;

    background: $gray-900;
    color: $light;

    .gk-admin-nav-inner {
      @extend .sticky-top;

      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }

    .gk-logo {
      @extend .px-3;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 8rem;
      background-color: $primary;

      svg {
        height: 3rem;
      }

      a {
        color: $light;
        text-decoration: none;
      }
    }

    .gk-admin-nav-item {
      @extend .py-3;
      @extend .px-3;
      @extend .small;

      display: block;
      cursor: pointer;
      border-top: 1px solid $gray-800;
      border-bottom: 1px solid $gray-800;
      text-decoration: none;
      color: $light;
      white-space: nowrap;
      max-width: 14rem;
      overflow-x: hidden;
      text-overflow: ellipsis;

      i {
        color: lighten($primary, 25%);
        margin-right: 1rem;
      }

      &.gk-active,
      &:hover {
        background-color: lighten($gray-900, 4%);

        i {
          color: $secondary;
        }
      }

      &:hover {
        color: $secondary;
      }

      &.gk-last {
        border-bottom: none;
      }
    }

    .gk-logo + .gk-admin-nav-item {
      border-top-color: transparent;
    }
  }

  .gk-admin-main {
    flex-grow: 1;

    .gk-admin-header {
      @extend .sticky-top;
      @extend .ps-5;
      @extend .pe-4;

      display: flex;
      align-items: center;
      height: 4rem;
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      z-index: 1049;
    }

    .gk-admin-content {
      @extend .py-4;
      @extend .ps-5;
      @extend .pe-4;
    }
  }

  .gk-expand-caption {
    cursor: pointer;
  }

  .gk-tabular-header {
    text-decoration: none;
    color: $body-color;
  }
}
